<template>
  <v-container v-if="assertion && badge && group">
    <v-row>
      <v-col cols="12" md="8" sm="6" class="d-flex justify-start align-center">
        <v-skeleton-loader
          v-if="!badge || !badge.image || !badge.image.base64Image"
          class="ma-2"
          style="height: 90px; width: 90px;"
          type="image"
        ></v-skeleton-loader>
        <transition name="fade-transition" mode="out-in">
          <v-img
            key="edit-image"
            v-if="badge && badge.image && badge.image.base64Image"
            @click="showBigImageDialog = true"
            class="mb-1"
            max-height="100"
            max-width="100"
            min-height="100"
            min-width="100"
            :src="badge.image.base64Image"
            contain
            style="width: 100px"
          />
        </transition>
        <v-skeleton-loader
          v-if="!badge"
          type="list-item-two-line"
          class="badge-name-loading"
          style="width: 500px"
        ></v-skeleton-loader>
        <transition name="slide-y-transition" mode="out-in">
          <span v-if="badge" class="ma-2 text-h4" key="edit-display-off">{{ assertion.user.firstName}} {{ assertion.user.lastName }}: {{ badge.displayName }} <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-chip v-if="assertion.isRevoked" color="error">
                    Revoked
                  </v-chip>
                </div>
              </template>
              <span>This assertion has been revoked. That means this credential is no longer valid</span>
            </v-tooltip></span>
        </transition>
      </v-col>
      <v-spacer></v-spacer>
      <transition name="slide-y-transition" mode="out-in">
        <v-col cols="12" md="3" class="d-flex justify-center align-center" v-if="!editModeEnabled && !assertion.isRevoked && hasAdminRole" key="edit-mode-disabled">
          <v-btn
            class="ma-1"
            color="primary"
            @click="editModeEnabled = true"
            :disabled="assertion.isRevoked"
          >Edit Assertion</v-btn>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-center align-center" v-if="!editModeEnabled && assertion.isRevoked" key="edit-mode-disabled">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
              <v-btn
                class="ma-1"
                color="primary"
                @click="editModeEnabled = true"
                :disabled="assertion.isRevoked"
              >
                Edit Assertion
              </v-btn>
              </div>
            </template>
            <span>This assertion has been revoked and cannot be edited. Issue a new assertion for this user and badge to re-issue</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" md="3" class="d-flex justify-center align-center" v-if="editModeEnabled" key="edit-mode-enabled">
          <div>
          <v-btn class="ma-1" color="error" :loading="isDeleting" :disabled="isDeleting" @click="displayRevokeDialog" style="width: 130px">Revoke</v-btn>
          <br/>
          <v-btn class="ma-1" :loading="isSubmitted" :disabled="isSubmitted" @click="updateAssertion" style="width: 130px">Save</v-btn>
          </div>
        </v-col>
      </transition>
    </v-row>
    <v-divider></v-divider>
    <v-container fluid>
    <v-row class="d-flex justify-center align-start">
      <v-col cols="12" md="9">
        <v-row>
          <v-col>
            <v-card class="card-details">
              <v-card-text>
                <v-container>
                  <v-row v-if="assertion.isRevoked">
                    <v-col>
                      <span class="text-h5">Overview</span>
                      <p class="ma-2">The badge <a :href="'/badges/details/' + badge.name">{{ badge.displayName }}</a> was revoked by <a :href="'/badges/groups/' + group.name">{{ group.displayName }}</a>
                        on {{ getLocalDate(assertion.revocationDateTimUtc) }}. This page is now in view-only mode.</p>
                    </v-col>
                  </v-row>
                  <v-row v-if="!assertion.isRevoked">
                    <v-col>
                      <span class="text-h5">Overview</span>
                      <p class="ma-2">This page demonstrates that {{ assertion.user.firstName}} {{ assertion.user.lastName }}
                        has completed all criteria required to obtain the <a :href="'/badges/details/' + badge.name">{{ badge.displayName }}</a> badge issued by <a :href="'/badges/groups/' + group.name">{{ group.displayName }}</a>
                        on {{ getLocalDate(assertion.issuedDateTimeUtc) }}.</p>
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col>
                        <span class="text-h5">Tags</span>
                          <div class="ml-3">
                            <v-chip
                              v-for="(chip, index) in badge.tags"
                              :key="index"
                              class="ma-2"
                            >
                              {{ chip }}
                            </v-chip>
                          </div>
                      </v-col>
                    </v-row>
                  <v-row>
                    <v-col>
                          <span class="text-h5">Badge Description</span>
                          <div class="ma-2" v-html="compiledMarkdown(badge.description)"></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="text-h5">Badge Criteria</span>
                      <div class="ma-2" v-html="compiledMarkdown(badge.criteria)"></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="text-h5">Group Description</span>
                      <div class="ma-2" v-html="compiledMarkdown(group.description)"></div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="card-options">
        <v-row>
          <v-col>
        <v-card class="card-details">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col v-if="assertion.status === null && oidcStore.user.sub.toLowerCase() === assertion.user.userId.toLowerCase() && !assertion.isRevoked">
                  <span class="text-h5">Owner Actions</span>
                  <div class="ma-2">
                    <div v-if="assertion.status === null">
                      <v-btn class="ma-2" color="primary" :disabled="isUpdating" :loading="isAccepting" @click="userAction('accept')">Accept</v-btn>
                    </div>
                    <div v-if="assertion.status === null">
                      <v-btn class="ma-2" color="error" :disabled="isUpdating" :loading="isDeclining" @click="userAction('decline')">Decline</v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h5">Status</span>
                  <div class="ma-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-chip v-if="assertion.isRevoked" color="error">
                            Revoked
                          </v-chip>
                        </div>
                      </template>
                      <span>This assertion has been revoked. That means this credential is no longer valid</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-chip v-if="!assertion.isRevoked && hasExpired" color="error">
                            Expired
                          </v-chip>
                        </div>
                      </template>
                      <span>This assertion has been revoked. That means this credential is no longer valid</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-chip v-if="!assertion.isRevoked && !hasExpired && assertion.status === null">
                            Pending
                          </v-chip>
                        </div>
                      </template>
                      <span>A pending status indicates that the user has not yet accepted this badge</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-chip v-if="!assertion.isRevoked && !hasExpired && assertion.status === 0" color="success">
                            Accepted
                          </v-chip>
                        </div>
                      </template>
                      <span>An accepted status means the user has accepted this badge into their collection</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-chip v-if="!assertion.isRevoked && !hasExpired && assertion.status === 1" color="warning">
                            Declined
                          </v-chip>
                        </div>
                      </template>
                      <span>A declined status indicates that the user declined accepting this badge into their collection</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h5">Issued on</span>
                  <p class="ma-2">{{ getLocalDate(assertion.issuedDateTimeUtc) }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-h5">Expires on</span>
                  <transition name="slide-y-transition" mode="out-in">
                    <div v-if="editModeEnabled" key="edit-expiration-enabled">
                      <v-menu
                        ref="menu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="expirationDate"
                            label="Exiration Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            hint="A date in the future when this assertion should expire. Use this if certifications that this badge represents expire"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="expirationDate"
                          no-title
                          scrollable
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                    <div v-else key="edit-expiration">
                      <p class="ma-2" v-if="expirationDate">{{ getDateString(expirationDate) }}</p>
                      <p class="ma-2" v-else>No expiration date</p>
                    </div>
                  </transition>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p><span class="text-h5">User Badges</span></p>
                  <v-btn class="ma-2" :to="'/badges/user/' + this.assertion.user.userId">View user's badges</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showBigImageDialog"
      width="500"
    >
      <v-card v-if="badge">
        <v-card-title>{{ badge.displayName }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="d-flex align-center justify-center">
              <v-img
                max-height="300"
                max-width="300"
                min-height="300"
                min-width="300"
                :src="badge.image.base64Image"
                contain
                style="width: 300px"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="assertion.user.userId.toLowerCase() === oidcStore.user.sub.toLowerCase()" text @click="downloadBadgeImage"><v-icon left>mdi-cloud-download</v-icon>Download</v-btn>
            <v-btn @click="showBigImageDialog = false">Close</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
import marked from 'marked'
import moment from 'moment'

export default {
  name: 'AssertionDetails',
  data: () => ({
    showBigImageDialog: false,
    expirationDate: null,
    isSubmitted: false,
    isDeleting: false,
    isUpdating: false,
    isDeclining: false,
    isAccepting: false,
    isHiding: false,
    assertionId: null,
    assertion: null,
    badge: null,
    group: null,
    editModeEnabled: false
  }),
  computed: {
    ...mapState(['oidcStore']),
    ...mapGetters(['badgeUser']),
    hasAdminRole: {
      get: function () {
        return this.badgeUser.roles.find(x => x === 'SystemAdmin') !== undefined || (this.group && this.group.users.find(x => x.userId.toLowerCase() === this.badgeUser.userId.toLowerCase()) !== undefined)
      }
    },
    hasExpired: {
      get: function () {
        if (this.assertion.expirationDateTimeUtc) {
          let date = moment.utc(this.assertion.expirationDateTimeUtc).local()

          return date.diff(moment(), 'days') < 0
        }
        return false
      }
    }
  },
  methods: {
    convertBase64ToFile: function () {
      let arr = this.badge.image.base64Image.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let uint8Array = new Uint8Array(n)
      while (n--) {
        uint8Array[n] = bstr.charCodeAt(n)
      }
      let file = new File([uint8Array], this.badge.displayName, { type: mime })
      return file
    },
    downloadBadgeImage: function () {
      const url = window.URL.createObjectURL(new Blob([this.convertBase64ToFile()]))
      const link = document.createElement('a')
      link.href = url
      if (this.badge.image.contentType === 'image/png') {
        link.setAttribute('download', `${this.badge.displayName}.png`)
      } else if (this.badge.image.contentType === 'image/svg') {
        link.setAttribute('download', `${this.badge.displayName}.svg`)
      }
      document.body.appendChild(link)
      link.click()
    },
    displayRevokeDialog: function () {
      this.$store.commit('showDialog', {
        message: 'Are you sure you wish to revoke this assertions? <br/><br/>This action can not be undone and will result in notifications to the user about the revocation. <br/><br/>Revocation will stop any further updates on this assertion and new assertions will need to be created to re-issue this badge to the affected employee.',
        confirmMethod: () => {
          this.revokeAssertion()
        },
        cancelMethod: () => {
          return false
        }
      })
    },
    revokeAssertion: async function () {
      this.isDeleting = true
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/assertions/${this.assertion.id}/revoke`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          () => {
            this.isDeleting = false
            this.editModeEnabled = false
            this.getAssertion()
          },
          (error) => {
            this.isDeleting = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.revokeAssertion() })
          })
    },
    compiledMarkdown: function (input) {
      return marked(input, { sanitize: true })
    },
    getBadge: function (id) {
      this.isLoading = true
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/badges/${id}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.isLoading = false
            this.badge = response.data
            this.getImage(this.badge)
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getBadge() })
          })
    },
    getGroup: function (id) {
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/groups/${id}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.group = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getGroup() })
          })
    },
    getAssertion: function () {
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/assertions/${this.assertionId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.assertion = response.data
            this.expirationDate = this.assertion.expirationDateTimeUtc ? moment.utc(this.assertion.expirationDateTimeUtc).local().format('YYYY-MM-DD') : null
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getAssertion() })
          })
    },
    getImage: function (badge) {
      if (badge.image) {
        return axios(
          `${process.env.VUE_APP_BADGEBASEAPIURL}/images/${badge.image.id}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
          .then(
            (response) => {
              this.badge.image = response.data
            },
            (error) => {
              this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: true, retryMethod: () => this.getImage() })
            })
      }
    },
    getDateString: function (date) {
      return moment(date).format('LL')
    },
    getLocalDate: function (date) {
      return moment.utc(date).local().format('LL')
    },
    updateAssertion: function () {
      this.isSubmitted = true
      // Add the date time to the datetime for the start of the selected day
      // This will ensure our UTC time is correct
      if (this.expirationDate) {
        let startDateTime = moment(this.expirationDate).startOf('day')
        this.assertion.expirationDateTimeUtc = startDateTime.toISOString(true)
      }

      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/assertions/${this.assertionId}`,
        {
          method: 'PUT',
          data: JSON.stringify(this.assertion),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.assertion = response.data
            this.editModeEnabled = false
            this.isSubmitted = false
            this.expirationDate = response.data.expirationDateTimeUtc
          },
          (error) => {
            this.isSubmitted = false
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.updateAssertion() })
          })
    },
    userAction: function (action) {
      if (action === 'accept') {
        this.isAccepting = true
      } else if (action === 'decline') {
        this.isDeclining = true
      } else {
        this.isHiding = true
      }

      this.isUpdating = true
      return axios(
        `${process.env.VUE_APP_BADGEBASEAPIURL}/assertions/${this.assertionId}/userAction?action=${action}`,
        {
          method: 'PUT',
          data: JSON.stringify(this.assertion),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            this.assertion = response.data
            this.isUpdating = false
            this.isAccepting = false
            this.isDeclining = false
            this.isHiding = false
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.userAction(action) })
          })
    }
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.assertionId = this.$route.params.id
      this.getAssertion().then(() => {
        this.getGroup(this.assertion.groupId)
        this.getBadge(this.assertion.badgeId).then(() => {
          this.$store.commit('breadcrumbs', [
            {
              text: `Badges`,
              exact: true,
              to: `/badges`
            },
            {
              text: `${this.badge.displayName}`,
              exact: true,
              to: `/badges/details/${this.badge.name}`
            },
            {
              text: `Assertion for ${this.assertion.user.firstName} ${this.assertion.user.lastName}`,
              exact: true,
              to: `/badges/assertions/${this.assertionId}`
            }
          ])
        })
      })
    } else {
      alert('could not find assertions as an id was not provided in the url')
    }
  }
}
</script>

<style scoped>
.card-details {
  min-height: 610px;
}
</style>
